.workcard {
    align-items: center;
    align-self: flex-start;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 16px;
    min-height: 270px;
    padding: 8px;
    width: 45%;

    .workLogo {
        width: 80px;
    }

    .workInfo {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;

        .aboutWork {
            align-items: center;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 12px;
            width: 100%;
        }
    }

    .workDates {
        color: gray;
    }

    .workDescription {
        display: -webkit-box;
        max-width: 100%;
        overflow: hidden;
        text-align: justify;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;
    }
}

@media only screen and (max-width: 720px) {

    .workcard {
        width: 100%;
    }
}