.skillcard {
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: center;
    margin: 6px;
    padding: 8px 16px;
    width: max-content;

    .skillIcon {
        font-size: 80px;
    }

    .skillName {
        font-size: 18px;
    }
}

@media only screen and (max-width: 720px) {
    .skillcard {
        height: 100px;
        width: max-content;
    }

    .skillIcon {
        font-size: 60px;
    }
}