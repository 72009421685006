.topNavigator {
    bottom: 24px;
    color: #fff;
    display: flex;
    height: 48px;
    justify-content: flex-end;
    position: sticky;
    width: 100%;

    .navButton {
        align-items: center;
        background-color: #9461fd;
        border-radius: 50%;
        box-shadow: 0px 0px 4px 1px #4003e6;
        display: flex;
        height: 24px;
        justify-content: center;
        margin-right: 24px;
        transform: scale(2);
        width: 24px;
    }
}