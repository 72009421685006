.about {
  padding-bottom: 48px;
  
  .aboutTop {
    align-items: center;
    display: flex;
    height: 480px;
    justify-content: space-between;
  }

  .aboutInfo {
    font-size: 24px;

    .name {
      color: blueviolet;
      font-size: 36px;
      font-weight: 300;
      margin-bottom: 12px;
    }
  }

  .aboutBottom {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 720px) {
  .about {
    .aboutTop {
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .aboutInfo {
      font-size: 20px;

      .name {
        font-size: 26px;
      }
    }
  }
}