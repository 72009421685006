.menu {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;

  .webOption {
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    margin-right: 16px;

    // Aligning menu options
      align-items: center;
      color: #000;
      display: flex;
      text-decoration: none;

    .optionIcon {
      align-items: center;
      display: flex;
      margin-right: 4px;
    }

    i::before {
        margin-left: 10px;
        width: 24px;
      }
  }

  .webOption:last-child {
    margin-right: 0px;
  }
}

.darkMenu {
  .webOption {
    font-weight: 400;
    color: #eee;
  }
}

i {
  font-style: normal;
  display: flex;
}
