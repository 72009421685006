html {
  scroll-behavior: smooth;
  
  // Scroll Bar Styles
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #333;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
}

body {
  background: #777;
  filter: blur(0.5);
}

.home {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1440px;
}

.home.dark {
  background-color: #333;
  color: #eee;

  p {
  color: #eee;
  }
}

@media only screen and (max-width: 1024px) {
  .home {
    width: 96%;
  }
}

@media only screen and (min-width: 500px) {
  .home {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) and (min-width: 0px) {
  .home {
    width: 500px;
  }
}